import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { DesignPrinciple } from '~/components/DesignPrinciple';
import { PurposeIcon, AccessibilityIcon, DestinationIcon, ValueIcon, MapIcon } from '@entur/icons';
import { GridContainer } from '@entur/grid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <h2 {...{
      "id": "to-grunnleggende-prinsipper"
    }}>{`To grunnleggende prinsipper`}</h2>
    <p>{`De to første prinsippene er et fundament for hvordan vi skal jobbe.
Et mindset. Noen ganger motstridende, men akkurat derfor er det viktig å passe på at begge prinsippene blir ivaretatt.`}</p>
    <GridContainer spacing="large" style={{
      margin: '2rem 0 3rem'
    }} mdxType="GridContainer">
  <DesignPrinciple title="Det viktigste først" number={1} description="Når vi designer og utvikler tjenestene våre, skal vi ha fokus på å løse de viktigste problemene først. Vi skal ikke løse alle edge-caser, men konsentrere innsatsen på å lage det som gir størst verdi for flest mulig." icon={<PurposeIcon mdxType="PurposeIcon" />} mdxType="DesignPrinciple"></DesignPrinciple>
  <DesignPrinciple title="Alle om bord" number={2} description="Produktene vi lager skal være tilgjengelig for alle, uansett varige eller midlertidig reduserte evner. Tjenestene skal være intuitive å ta i bruk for både uerfarne brukere og eksperter." icon={<AccessibilityIcon mdxType="AccessibilityIcon" />} mdxType="DesignPrinciple"></DesignPrinciple>
    </GridContainer>
    <h2 {...{
      "id": "tre-prinsipper-for-brukeropplevelse"
    }}>{`Tre prinsipper for brukeropplevelse`}</h2>
    <p>{`De tre neste prinsippene handler om hvordan vi ønsker at våre produker skal oppleves av dem som benytter seg av dem.
Disse prinsippene skal være det vi strekker oss mot innen brukeropplevelse.
Faktisk uttak kan variere noe ut fra produkt og brukergruppe, men tolkningen av hva disse punktene betyr for akkurat det du lager, er viktig å ha på plass.`}</p>
    <GridContainer spacing="large" style={{
      margin: '2rem 0 3.5rem'
    }} mdxType="GridContainer">
  <DesignPrinciple title="Korteste vei til mål" number={3} description="Veien til produktets kjerneverdi skal være så kort og enkel som mulig. Vi skal fjerne unødig støy og hindringer i grensesnittet som kan forstyrre brukerens flyt." icon={<AccessibilityIcon mdxType="AccessibilityIcon" />} mdxType="DesignPrinciple"></DesignPrinciple>
  <DesignPrinciple title="Fokus til riktig tid" number={4} description="Produktene våre skal gi deg det du trenger når du trenger det. Vi skal ikke overvelde med all informasjon på én gang, men veilede fra A til B i små porsjoner og i relevant kontekst. Vi skal gi overblikk, deretter detaljer ved behov." icon={<AccessibilityIcon mdxType="AccessibilityIcon" />} mdxType="DesignPrinciple"></DesignPrinciple>
  <DesignPrinciple title="Umulig å gå seg vill" number={5} description="Produktene våre skal ha forståelige tekster og tydelige instruksjoner. Brukeren skal kunne utforske grensesnittet uten å være redd for å gjøre feil. Det skal være enkelt å sette i revers - lett å rette opp feil og lett å få hjelp." icon={<AccessibilityIcon mdxType="AccessibilityIcon" />} mdxType="DesignPrinciple"></DesignPrinciple>
    </GridContainer>
    <h2 {...{
      "id": "forslag-til-hvordan-designprinsippene-kan-brukes"
    }}>{`Forslag til hvordan designprinsippene kan brukes`}</h2>
    <p>{`De fem designprinsippene eksisterer også som egne skissekomponenter i Figma.
Det er ikke tilfeldig at de minner om spillekort; det skal være enkelt å trekke frem et prinsipp ur ermet og benytte det som argument.
Eller bare referere til dem i forbindelse med en justering som løser et problem bedre enn dagens løsning.`}</p>
    <p>{`Designprinsipp-kortene kan benyttes enkeltvis eller sammen.
Lim dem inn i skisser, som vedlegg i Jira, print dem ut og heng dem opp der du eller teamet ditt kan se dem.
Husk at god og gjennomtenkt design er viktig for oss i Entur, og at det er en oppgave som løses best i tverfaglig samarbeid.
Designprinsippene er ikke bare relevante for designere, men for alle som jobber med digital produktutvikling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      